@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Euclid Circular A:wght@200..800&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("./fonts.css");

:root {
  --primary-100: 50, 118, 250;
  --primary-200: 232, 241, 253;

  --dark-100: 28, 32, 36;

  --gray-100: 217, 217, 217;
  --gray-200: 135, 138, 144;
  --gray-300: 96, 100, 108;
  --gray-400: 139, 141, 152;

  --light-100: 240, 247, 255;

  --danger-100: 254, 236, 235;
  --danger-200: 243, 105, 96;

  --success-100: 65, 197, 136;
  --success-200: 231, 248, 240;

  --body-font: "Euclid Circular A", sans-serif;
}

div {
  @apply !font-body;
}

h1 {
  @apply !font-body;
}

ul.initial {
  list-style: disc;
  margin: 0;
  padding: 0;
}

ol.initial {
  list-style: decimal;
  margin: 0;
  padding: 0;
}

@layer base {
  body {
    color: var(--body-color, #000);
    overflow-wrap: break-word;
    word-break: break-word;
    font-family: "Euclid Circular A", sans-serif !important;
    background-color: #fff;
    overflow: hidden;
  }

  select,
  textarea,
  [type="text"],
  [type="email"],
  [type="password"] {
    padding: theme("spacing.2") theme("spacing.4");
    border-radius: theme("borderRadius.DEFAULT");
    border-color: theme("colors.gray.200");
  }

  [type="checkbox"],
  [type="radio"] {
    color: theme("colors.primary.100");
    border-radius: theme("borderRadius.DEFAULT");
    border-color: theme("colors.gray.200");
  }

  textarea:focus,
  [type="text"]:focus,
  [type="email"]:focus,
  [type="password"]:focus,
  [type="checkbox"]:focus,
  [type="radio"]:focus {
    --tw-ring-color: rgba(var(--primary), 0.5);
    border-color: rgb(var(--primary));
  }

  .hide-arrows input[type="number"]::-webkit-inner-spin-button {
    display: none;
    opacity: 0 !important;
  }

  .hide-arrows input[type="number"]::-webkit-inner-spin-button,
  .hide-arrows input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .hide-arrows input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;
  }

  .show-arrows input[type="number"]::-webkit-inner-spin-button {
    opacity: 1;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #ffffff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid rgb(var(--primary));
  }

  input {
    @apply !font-body;
  }
}

@layer components {
  * {
    @apply font-body focus:outline-none focus:ring-0 focus:shadow-none;
  }
  .main-header {
    @apply !bg-transparent !w-full !flex !gap-7;
  }

  .main-header.ant-menu {
    @apply !font-body lg:!flex !justify-start;
  }

  .main-header .ant-menu-item {
    @apply !text-white !text-sm !font-medium !mx-2;
  }

  .main-header .ant-menu-item:hover::after {
    @apply !border-b-white;
  }

  .main-header .ant-menu-item-selected {
    @apply !border-b-2 !rounded-none !border-white !bg-transparent !text-white !font-bold;
  }

  .main-header .ant-menu-item {
    @apply !h-auto !w-auto !p-0;
  }

  .main-header .ant-menu-item:hover {
    @apply !border-b-2 !rounded-none !border-white !bg-transparent !text-white;
  }

  .main-header-m .ant-menu-overflow-item {
    @apply !p-0;
  }

  .main-header-m .ant-menu-submenu-title {
    @apply !text-white;
  }

  .main-header-m {
    @apply !bg-transparent;
  }

  .main-header-m .ant-menu-submenu:hover::after,
  .main-header-m .ant-menu-submenu-active::after,
  .main-header-m .ant-menu-submenu-open::after {
    @apply !border-b-white;
  }

  .nav-bar {
    @apply !bg-transparent;
  }

  .nav-bar.ant-menu-horizontal {
    @apply !border-0;
  }

  .nav-bar .ant-menu-overflow-item {
    @apply !pl-0 !mr-4;
  }

  .nav-bar .ant-menu-item::after {
    @apply !left-0 !w-auto;
  }

  .review-request-table .ant-table {
    @apply !border !border-primary-200 !rounded-md;
  }

  .review-request-table .ant-table-wrapper .ant-table-thead > tr > th,
  .review-request-table .ant-table-wrapper .ant-table-thead > tr > td {
    @apply !bg-light-100 !border-primary-200;
  }

  .review-request-table .ant-table-wrapper .ant-table-thead > tr > th::before {
    @apply !border-0;
  }

  .review-request-table .ant-table-cell {
    @apply !text-gray-300 !font-medium;
  }

  .review-request-table .ant-pagination .ant-pagination-item a {
    @apply !text-gray-200 !text-sm;
  }

  .review-request-table .ant-pagination .ant-pagination-item-active a {
    @apply !text-primary-100;
  }

  .review-request-table
    .ant-pagination
    .ant-pagination-item:not(.ant-pagination-item-active):hover {
    @apply !bg-light-100;
  }

  .review-request-table .ant-pagination .ant-pagination-item-active {
    @apply !bg-light-100 !border-light-100 !text-primary-100 !font-medium !flex !justify-center !items-center;
  }

  .review-request-table .ant-pagination-item-link {
    @apply !bg-white !border !border-gray-400 !rounded-lg !text-gray-400 !h-10 relative;
  }

  .review-request-table .ant-pagination-item-link .anticon-right {
    @apply !relative !right-2 !w-full !min-w-80px !flex !justify-end after:content-['Next'] after:!absolute after:!left-5 after:!text-sm after:!whitespace-nowrap;
  }

  .review-request-table .ant-pagination-item-link .anticon-left {
    @apply !relative !left-2 !w-full !min-w-100px !flex !justify-start after:content-['Previous'] after:!absolute after:!right-5 after:!text-sm after:!whitespace-nowrap;
  }

  .review-request-table
    .ant-table-wrapper
    .ant-table-pagination.ant-pagination {
    @apply !items-baseline !justify-center !gap-3 !mt-6;
  }

  .chart-selector {
    @apply !mx-2 !absolute !top-3 !left-0 z-1;
  }

  .chart-selector .ant-select-selector {
    @apply !border-0 !bg-light-100 md:!text-base !text-sm;
  }

  .dashboard-chart .apexcharts-canvas {
    @apply !pt-5px;
  }

  .dashboard-chart .apexcharts-legend-text {
    @apply !text-[#525256] !text-xs !font-medium !font-body;
  }

  .drawer-m .ant-drawer-header {
    @apply !p-0 !border-0 !absolute !top-2 !right-1;
  }

  .drawer-m .ant-drawer-header-title .ant-drawer-close {
    @apply !text-white !font-bold;
  }

  .drawer-m .ant-drawer-body {
    @apply !py-40px !px-10px;
  }

  .chat-search-mini .ant-input-affix-wrapper {
    @apply !bg-light-100 !border-light-100 !h-12 placeholder:!text-gray-200 !font-body !text-base !font-medium;
  }

  .chat-search-mini .ant-input-search-button {
    @apply !bg-light-100 !border-light-100 !h-12;
  }

  .chat-search-mini.ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    @apply text-gray-200;
  }

  .chat-search-mini .ant-btn-icon {
    position: relative;
    right: 8px;
    top: 2px;
  }

  .chat-search-mini .ant-input-outlined:focus-within {
    box-shadow: none !important;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary):hover {
    @apply !text-primary-100;
  }

  .nav-bar-m {
    @apply !bg-transparent !w-full !flex !gap-7;
  }

  .nav-bar-m.ant-menu {
    @apply !font-body lg:!flex !justify-start;
  }

  .nav-bar-m .ant-menu-item {
    @apply !text-black/80 !text-sm !font-medium !mx-2;
  }

  .nav-bar-m .ant-menu-item:hover::after {
    @apply !border-b-[#1677ff];
  }

  .nav-bar-m .ant-menu-item-selected {
    @apply !border-b-2 !rounded-none !border-[#1677ff] !bg-transparent !text-[#1677ff];
  }

  .nav-bar-m .ant-menu-item {
    @apply !h-auto !w-auto !p-0;
  }

  .nav-bar-m .ant-menu-item:hover {
    @apply !border-b-2 !rounded-none !border-[#1677ff] !bg-transparent !text-black/80;
  }

  .drawer-nav-m .ant-drawer-body {
    @apply !py-40px !px-10px;
  }

  .drawer-nav-m .ant-drawer-header-title .ant-drawer-close {
    @apply !text-black !font-bold;
  }

  .drawer-nav-m .ant-drawer-header {
    @apply !p-0 !border-0 !absolute !top-2 !right-1;
  }
}

:where(.css-dev-only-do-not-override-djtmh8).ant-menu-light
  .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-djtmh8).ant-menu-light
  > .ant-menu
  .main-header-m
  .ant-menu-item-selected {
  @apply !text-dark-100 !font-semibold !bg-light-100;
}

:where(.css-dev-only-do-not-override-djtmh8).main-header.ant-menu-light
  .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-djtmh8).ant-menu-light
  > .ant-menu
  .ant-menu-item-selected {
  @apply !bg-transparent !text-white !border-b-2 !border-white !rounded-none;
}

:where(
    .css-dev-only-do-not-override-1kuana8
  ).ant-menu-light.ant-menu-root.ant-menu-inline,
:where(.css-dev-only-do-not-override-1kuana8).ant-menu-light
  > .ant-menu.ant-menu-root.ant-menu-inline,
:where(
    .css-dev-only-do-not-override-1kuana8
  ).ant-menu-light.ant-menu-root.ant-menu-vertical,
:where(.css-dev-only-do-not-override-1kuana8).ant-menu-light
  > .ant-menu.ant-menu-root.ant-menu-vertical {
  border-inline-end: 0 !important;
}

.upper-canvas {
  position: relative !important;
}

.react-flow-node .ant-input-group-addon {
  background: #ffffff;
  border: none;
  padding: 0;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
 
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 215 20.2% 65.1%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
 
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
 
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
 
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
 
    --border: 216 34% 17%;
    --input: 216 34% 17%;
 
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
 
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
 
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 216 34% 17%;
 
    --radius: 0.5rem;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

.email-content *[data-selected="true"] {
  outline: 2px solid #0066ff !important;
  position: relative !important;
  z-index: 1 !important;
  pointer-events: auto !important;
}

.email-content *[data-hover="true"] {
  outline: 2px dashed #666666 !important;
  position: relative !important;
  z-index: 1 !important;
  pointer-events: auto !important;
}

.page-content *[data-selected="true"] {
  outline: 2px solid #0066ff !important;
  position: relative !important;
  z-index: 1 !important;
  pointer-events: auto !important;
}

.page-content *[data-hover="true"] {
  outline: 2px dashed #666666 !important;
  position: relative !important;
  z-index: 1 !important;
  pointer-events: auto !important;
}

[data-hover="true"] {
  outline: 2px dashed #1890ff;
  cursor: pointer;
}

[data-selected="true"] {
  outline: 2px solid #1890ff;
}