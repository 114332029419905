/* Define the Euclid Circular A font family */
@font-face {
  font-family: 'Euclid Circular A';
  src: url('/assets/fonts/euclid-circular-a/Euclid Circular A Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('/assets/fonts/euclid-circular-a/Euclid Circular A Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('/assets/fonts/euclid-circular-a/Euclid Circular A Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('/assets/fonts/euclid-circular-a/Euclid Circular A Bold Italic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('/assets/fonts/euclid-circular-a/Euclid Circular A Light.ttf') format('truetype');
  font-weight: lighter; /* You can adjust the weight based on your preference */
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('/assets/fonts/euclid-circular-a/Euclid Circular A Medium.ttf') format('truetype');
  font-weight: 500; /* Medium weight */
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('/assets/fonts/euclid-circular-a/Euclid Circular A Medium Italic.ttf') format('truetype');
  font-weight: 500; /* Medium weight */
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('/assets/fonts/euclid-circular-a/Euclid Circular A SemiBold.ttf') format('truetype');
  font-weight: 600; /* SemiBold weight */
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('/assets/fonts/euclid-circular-a/Euclid Circular A SemiBold Italic.ttf') format('truetype');
  font-weight: 600; /* SemiBold weight */
  font-style: italic;
}
